@import '../../../../scss/theme-bootstrap';
@import '../../../../scss/components/_mpp_filters';
@import '../../../../scss/components/_sd_style_overrides';

@mixin grid-container {
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;
}

.sd-product-grid {
  max-width: 100%;
  .elc-styled-filters {
    background-color: transparent;
    box-shadow: none;
    margin: 15px 0;
    &-checkbox-label {
      @include body-text;
      @include swap_direction(padding, 0 35px 0 0);
      color: $color-black;
      width: 50%;
      margin: 0 0 16px 0;
      @include breakpoint($landscape-up) {
        width: 33%;
      }
      @include breakpoint($xlarge-up) {
        width: 25%;
      }
      .elc-checkbox {
        display: inline-flex;
        align-items: center;
        &-label {
          @include body-text;
          line-height: 1;
          margin: 0;
          padding: 0;
          height: auto;
        }
        &-check-area {
          overflow: hidden;
          background-color: $white;
          margin-#{$rdirection}: 12px;
          width: 20px;
          height: 20px;
          border: 1px solid $color-cream-stroke;
          display: inline-flex;
          top: unset;
        }
        &-icon {
          background: none;
          -webkit-mask: none;
          mask: none;
        }
      }
      .elc-checkbox-selected {
        border: 1px solid $color-primary-700;
        .elc-checkbox-icon {
          @include svg-icon-inline-mask('tick_box_checked.svg');
          background: $color-primary-700;
          transform: scale(1.7);
          height: 18px;
          width: 20px;
        }
      }
    }
    &-category-row {
      @include swap_direction(padding, 0 0 0 20px);
      flex: 0 300px;
      margin: 0;
      order: 1;
      @include breakpoint($medium-up) {
        flex: 1;
      }
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 0 0 $container-pad-landscape);
      }
      .elc-grid-column {
        padding: 0;
        width: auto;
      }
    }
    &-category-tab-wrapper {
      display: inline-block;
    }
    &-clear-all-button-wrapper {
      flex: 1;
    }
    &-clear-all-button,
    &-category-tab {
      @include body-text;
      color: $color-black;
      background-color: transparent;
      height: auto;
      line-height: 1;
      padding-#{$ldirection}: 0px;
      padding-#{$rdirection}: 20px;
      &:hover,
      &:active {
        background: none;
      }
    }
    &-clear-all-button {
      #{$ldirection}: 300px;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 2px;
      order: 2;
      position: absolute;
      text-decoration: underline;
      text-transform: none;
      .elc-icon {
        display: none;
      }
    }
    &-active-filters-no {
      &:before {
        content: '[';
      }
      &:after {
        content: ']';
      }
    }
    &-category-tab {
      position: relative;
      &:after {
        @include svg-icon-inline-mask('chevron.svg');
        background: $color-black;
        content: '';
        display: inline-block;
        height: 20px;
        margin-#{$ldirection}: 9px;
        transform: rotate(90deg);
        transition: transform 0.25s;
        vertical-align: middle;
        width: 20px;
      }
      &.active {
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
    &-selected-category-row {
      order: 3;
      flex: 1;
      padding: 0;
      margin: 0;
    }
    &-button-row {
      border-bottom: 1px solid $color-cream-stroke;
      margin-top: 30px;
      order: 4;
      padding-#{$ldirection}: $container-pad-landscape;
      padding-#{$rdirection}: $container-pad-landscape;
      width: 100%;
    }
    &-applied-filter,
    &-result-row {
      display: none;
    }
  }

  // Product grid skeleton inside blog posts.
  .sd-mount-skeleton {
    .blog-post & {
      @include breakpoint($landscape-up) {
        @include container-max-width-landscape-indented;
      }
    }
  }

  .sd-grid-skeleton {
    .sd-grid-skeleton-items {
      @include grid-container();
    }
  }

  [data-component='product-grid'] {
    overflow: hidden;
    .elc-grid-container {
      margin: 0;
    }
    .elc-sort-wrapper {
      @include slide-animation('right', 'in');
      @include slide-animation('left', 'in');
      @include swap_direction(margin, 0 18px 0 auto);
      @include swap_direction(padding, 0 24px 0 0);
      background: none;
      min-width: auto;
      order: 3;
      position: relative;
      top: 11px;
      width: auto;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 0 0 auto);
      }
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 $container-pad-landscape 0 0);
      }
      .elc-dropdown {
        box-shadow: none;
        &-wrapper {
          padding: 0 12px;
        }
        &-arrow {
          border: none;
          height: 24px;
          padding-bottom: 4px;
          padding-top: 2px;
          position: relative;
          right: 17px;
          top: -3px;
          transition: all 0.25s;
        }
        &-readonly-input {
          border-bottom: 1px solid transparent;
          height: 18px;
          justify-content: flex-start;
          line-height: 1.5;
          padding: 0 30px 5px 4px;
          transition: all 0.1s ease-in-out;
          width: fit-content;
        }
        &-options {
          min-width: 180px;
        }
        &-options-list {
          height: auto;
          overflow: hidden;
          padding: 0 12px;
          width: auto;
        }
        &-option {
          font-size: 14px;
          line-height: 0.95;
          padding: 16px 4px;
          // Using this instead of :nth-of-type(1) to avoid accidentally hiding first item if/when the 'Sort by' option is removed in React
          &:first-child {
            display: none;
          }
          &:nth-of-type(2) {
            padding: 24px 4px 16px;
          }
          &:last-of-type {
            padding: 16px 4px 24px;
          }
        }
      }
      div[aria-expanded='true'] {
        .elc-dropdown {
          &-options {
            @include ternary(
              $text-direction == ltr,
              animation,
              slideFadeInFromRight 0.1s forwards,
              slideFadeInFromLeft 0.1s forwards
            );
          }
        }
        .elc-dropdown-readonly-input {
          border-bottom-color: $color-cream-stroke;
          padding-#{$ldirection}: 4px;
        }
      }
    }
    .elc-product-grid-top-border {
      @include body-text;
      background: transparent;
      box-shadow: none;
      position: relative;
    }
    .elc-mobile-filters-modal-button {
      @include body-text;
      background: transparent;
      border: 0;
      color: $color-black;
      display: inline-block;
      font-weight: normal;
      padding: 0;
      text-transform: capitalize;
      width: auto;
      &:after {
        @include svg-icon-inline-mask('chevron.svg');
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        background: $color-black;
        margin-#{$ldirection}: 9px;
        transform: rotate(90deg);
        vertical-align: middle;
      }
      .elc-filter-icon {
        display: none;
      }
    }
    .elc-styled-category-filters-result-row {
      @include breakpoint($medium-up) {
        padding-top: 40px;
      }
    }
    .elc-mobile-filters-modal-wrapper {
      height: 45px;
    }
    .elc-product-full-cta-wrapper {
      background: none;
      box-shadow: none;
      margin: 0 auto;
      padding: 0;
      position: relative;
      width: auto;
      z-index: 0;
      .elc-product-cta-wrapper {
        margin: 0;
        .elc-add-to-bag-button {
          margin: 0 auto;
          &-disabled {
            background: transparent;
            color: $black;
            &:hover {
              background: transparent;
            }
          }
        }
        .elc-product-notify-me-button {
          color: $black;
          background-color: transparent;
          height: 40px;
          margin: 0 auto;
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .elc-size-picker-wrapper {
      display: flex;
      justify-content: center;
      width: fit-content;
    }
    // Product grid inside blog posts.
    .blog-post & {
      @include breakpoint($landscape-up) {
        @include container-max-width-landscape-indented;
        padding-#{$ldirection}: $container-pad-landscape;
        padding-#{$rdirection}: $container-pad-landscape;
      }
      .elc-grid-container {
        margin: 0;
      }
      .elc-accordion {
        .elc-grid-container {
          margin: 20px 0 32px;
          min-height: auto;
          @include breakpoint($medium-up) {
            margin: 64px 0 48px;
          }
        }
        .elc-product-quick-view-button-wrapper {
          min-height: auto;
        }
        .elc-product-brief-view {
          overflow: auto;
        }
      }
    }
    .elc-product-brief-view {
      @include grid-container();
    }
    &[data-config*='"enableAccordion":true'] {
      min-height: unset;
    }
    .elc-mobile-filters {
      .elc-sort-wrapper {
        @include swap_direction(padding, 0 29px 0 0);
        top: unset;
      }
    }
  }
}

.breadcrumbs {
  background: none;
}

.elc-mobile-filters {
  .elc-mobile-filters-modal-wrapper {
    padding: 10px 20px;
  }
  .elc-mobile-attribute-filters-results {
    display: none;
  }
  .elc-sort-wrapper {
    @include swap_direction(padding, 0 29px 0 0);
    flex: initial;
    top: unset;
    &:after {
      @include svg-icon-inline-mask('chevron.svg');
      background: $color-black;
      bottom: 0;
      content: '';
      display: inline-block;
      height: 16px;
      margin: auto 0;
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
      transform: rotate(90deg);
      width: 16px;
    }
    select {
      @include ternary($text-direction == ltr, direction, rtl, ltr);
      background: none;
      color: $black;
      option {
        direction: $text-direction;
      }
    }
  }
}

.elc-responsive-modal-mask {
  .elc-grid-column {
    padding: 0;
  }
  .elc-mobile-filters-modal {
    #{$ldirection}: 0;
    @include body-text;
    background: $color-cream;
    color: $color-black;
    height: 100%;
    overflow-x: hidden;
    padding: 0;
    text-transform: capitalize;
    top: 0;
    transform: none;
    &:before {
      #{$ldirection}: 0;
      background: $color-cream;
      content: '';
      display: block;
      height: 100%;
      pointer-events: none;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: -1;
    }
    .elc-mobile-category-filters-remove-icon-wrapper {
      #{$rdirection}: -4px;
      background-color: transparent;
      position: absolute;
      top: 2px;
      .elc-remove-icon {
        height: 20px;
        transform: scale(1.5);
        width: 20px;
      }
    }
    .elc-mobile-category-filters-title {
      @include title--h1;
      color: inherit;
      padding: 40px 0 38px;
      text-align: center;
      width: 100%;
    }
    .elc-styled-filters {
      &-category-tab {
        @include body-text;
        align-items: center;
        background: transparent;
        color: $color-black;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        padding: 0 22px;
        text-align: #{$ldirection};
        text-transform: uppercase;
        width: 100%;
        &:after {
          @include svg-icon-inline-mask('plus.svg');
          background: $color-black;
          color: $color-black;
          content: '';
          display: block;
          height: 16px;
          transform: scale(2);
          width: 16px;
        }
        &.active {
          &:after {
            @include svg-icon-inline-mask('minus.svg');
          }
        }
      }
      &-category-row {
        padding-bottom: 48px;
        padding-top: 0;
      }
      &-button-row {
        background-color: $color-white;
        padding: 40px 20px;
      }
      &-checkbox-label {
        height: 20px;
        line-height: 0;
        margin: 20px 20px 0;
        padding: 0;
        width: 100%;
        .elc-checkbox {
          align-items: center;
          display: inline-flex;
          &-label {
            @include body-text;
            height: auto;
            line-height: 1;
            margin: 0;
            padding: 0;
          }
          &-check-area {
            background-color: $white;
            border: 1px solid $color-cream-stroke;
            display: inline-flex;
            height: 20px;
            margin-#{$rdirection}: 12px;
            overflow: hidden;
            top: unset;
            width: 20px;
          }
          &-icon {
            background: none;
            -webkit-mask: none;
            mask: none;
          }
        }
        .elc-checkbox-selected {
          border: 1px solid $color-primary-700;
          .elc-checkbox-icon {
            @include svg-icon-inline-mask('tick_box_checked.svg');
            background: $color-primary-700;
            height: 20px;
            transform: scale(1.7);
            width: 20px;
          }
        }
        &:nth-of-type(1) {
          margin-top: 0;
        }
      }
      &-active-filters-no {
        @include swap_direction(padding, 0 0 0 6px);
        flex: 1;
      }
    }
    .elc-styled-filters-clear-all-button,
    .elc-mobile-apply-filter-button {
      @include body-text;
      border-radius: 0;
      border: 1px solid $color-black;
      flex: 1;
      height: 56px;
      line-height: 1;
    }
    .elc-mobile-apply-filter-button {
      .elc-mobile-apply-filter-label {
        @include body-text;
        color: $color-cream;
      }
    }
    .elc-styled-filters-clear-all-button {
      background-color: $color-cream;
      color: $color-black;
      margin: 0;
      .elc-remove-icon {
        display: none;
      }
    }
    .elc-mobile-filters-cta {
      #{$ldirection}: 0;
      bottom: 15px;
      display: flex;
      position: fixed;
      width: 100%;
    }
  }
}

.olapic-slider-wrapper {
  direction: initial;
}

// Engraving popup style override on MPP.
.engraving-preview-popup.quick-shop-opened {
  position: fixed !important;
}
