.sd-product-grid,
.sd-product-spp__product-cross-sell,
.sd-product-spp .sd-product-cross-sell,
.sd-search-results,
.elc-search-results-message-wrapper,
.elc-search-results-wrapper,
.elc-product-quick-view-wrapper,
.elc-product-full,
.elc-sticky-menu-product-cta {
  .elc-product-notify-me-button,
  .elc-product-quick-view-button,
  .elc-add-to-bag-button,
  .elc-add-to-collection-button,
  .elc-preorder-button,
  .elc-preorder-button-disabled,
  .elc-skeleton-feature--addToBag {
    [data-cta-style='elc-link elc-link--caps'] & {
      @include link--caps();
    }
    [data-cta-style='elc-link'] & {
      @include link();
    }
    [data-cta-style='button button--dark'] & {
      @include button--dark();
    }
    [data-cta-style='button button--dark button--large'] & {
      @include button--dark();
      @include button--large();
    }
    [data-cta-style='button button--large-disabled'] & {
      @include button--large-disabled();
    }
    [data-cta-style='button button--light'] & {
      @include button--light();
    }
    [data-cta-style='button button--secondary'] & {
      @include button--secondary();
    }
    [data-cta-style='button button--white'] & {
      @include button--white();
    }
    [data-cta-style='button button--disabled'] & {
      @include button--white();
    }
    [data-cta-style='button button--dark-secondary'] & {
      @include button--dark-secondary();
    }
    [data-cta-style='button button--transparent'] & {
      @include button--transparent();
    }
    [data-cta-style='button button--auto-width'] & {
      @include button--auto-width();
    }
    [data-cta-style='button button--auto-width-border-radius'] & {
      @include button--auto-width-border-radius();
    }
    [data-cta-style='cta-pill-stroke'] & {
      @include cta-pill-stroke();
    }
    [data-cta-style='cta-pill-stroke--dark'] & {
      @include cta-pill-stroke--dark();
    }
    [data-cta-style='cta-pill-stroke--white'] & {
      @include cta-pill-stroke--white();
    }
    [data-cta-style='cta-rouded-stroke--blue'] & {
      @include cta-rouded-stroke--blue();
      width: 100%;
      max-width: #{'min(90%, 160px)'};
      @include breakpoint($medium-up) {
        max-width: #{'min(90%, 280px)'};
      }
    }
    [data-cta-style='button-small'] & {
      @include button-small;
    }
    [data-cta-style='button-small--border-radius'] & {
      @include button-small--border-radius;
    }
    .custom-bg & {
      background: var(--sd-cta-bg-color, inherit);
    }
    .custom-text & {
      color: var(--sd-cta-text-color, inherit);
      &:hover {
        color: var(--sd-cta-text-color, inherit);
      }
    }
  }
  [data-component='product-grid'] .elc-product-full-cta-wrapper {
    [data-cta-style='cta-rouded-stroke--blue'] & {
      width: 100%;
    }
  }
}
