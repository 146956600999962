//@import "../_theme_bootstrap";

.sd-product-grid,
.elc-mobile-filters-modal {
  .elc-product-grid {
    &--header {
      background-color: transparent;
      box-shadow: none;
      margin: 15px 0;
      position: relative;
      z-index: 1;
      // specificity for backwards compatibility override
      .elc-orderable-filters-wrapper ~ div.elc-sort-wrapper {
        @include swap_direction(padding, 0 29px 0 0);
        display: flex;
        flex: initial;
        top: unset;
        &:after {
          @include svg-icon-inline-mask('chevron.svg');
          background: $color-black;
          bottom: 0;
          content: '';
          display: inline-block;
          height: 16px;
          margin: auto 0;
          position: absolute;
          #{$rdirection}: 0;
          top: 0;
          transform: rotate(90deg);
          width: 16px;
        }
        select {
          @include ternary($text-direction == ltr, direction, rtl, ltr);
          background: none;
          color: $black;
          option {
            direction: $text-direction;
          }
        }
        @include breakpoint($medium-up) {
          &:after {
            content: none;
          }
          position: absolute;
          top: 10px;
          #{$rdirection}: $container-pad-landscape;
          padding: 0;
        }
      }
      .elc-orderable-filters-wrapper {
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
    }
    &--filters-section {
      margin: 0;
      @include breakpoint($medium-up) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: 40px;
      }
    }
    &--clear-all,
    &--apply-filter {
      @include body-text;
      border-radius: 0;
      border: 1px solid $color-black;
      height: 56px;
      line-height: 1;
      position: fixed;
      bottom: 0;
      #{$ldirection}: 0;
    }
    &--apply-filter {
      #{$ldirection}: 0;
      display: flex;
      width: 100%;
      align-items: center;
      &-label {
        @include body-text;
        color: $color-cream;
        flex: 1;
      }
    }
    &--clear-all ~ .elc-product-grid--apply-filter {
      width: 50%;
      #{$ldirection}: unset;
      #{$rdirection}: 0;
    }
    &--clear-all {
      color: $color-black;
      background-color: $color-cream;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 2px;
      width: 50%;
      @include breakpoint($medium-up) {
        border: 0;
        text-decoration: underline;
        text-transform: none;
        width: auto;
        height: 32px;
        position: relative;
        padding-#{$ldirection}: 0px;
        padding-#{$rdirection}: 20px;
        top: 0;
      }
      .elc-icon {
        display: none;
      }
      &:focus {
        outline: none;
      }
      &:hover,
      &:active {
        background: none;
      }
    }
    &--filter-set {
      @include body-text;
      position: relative;
      align-items: center;
      background: transparent;
      color: $color-black;
      line-height: 1;
      text-align: #{$ldirection};
      text-transform: uppercase;
      width: 100%;
      padding: 0 22px;
      justify-content: space-between;
      display: flex;
      &:focus-visible {
        outline-offset: 1px;
      }
      &:active {
        background-color: transparent;
      }
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 10px 20px 10px 0);
        height: 40px;
        text-transform: capitalize;
        display: inline-block;
        &:focus {
          outline: none;
        }
      }
      &:hover {
        background: transparent;
      }
      &:after {
        @include svg-icon-inline-mask('plus.svg');
        background: $color-black;
        color: $color-black;
        content: '';
        display: flex;
        height: 16px;
        transform: scale(2);
        width: 16px;
        margin-#{$ldirection}: 9px;
        @include breakpoint($medium-up) {
          @include svg-icon-inline-mask('chevron.svg');
          width: 20px;
          height: 20px;
          transform: rotate(90deg);
          transition: transform 0.25s;
          vertical-align: middle;
          display: inline-block;
        }
      }
      &.active {
        &:after {
          @include svg-icon-inline-mask('minus.svg');
          @include breakpoint($medium-up) {
            @include svg-icon-inline-mask('chevron.svg');
            transform: rotate(-90deg);
          }
        }
      }
      &-wrapper {
        display: inline-block;
        width: 100%;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 0 0 $container-pad-landscape);
          width: unset;
        }
        &:last-of-type {
          padding-bottom: 70px;
          @include breakpoint($medium-up) {
            padding-bottom: 44px;
          }
        }
      }
    }
    &--filter-options-wrapper {
      background-color: $color-white;
      padding: 40px 20px 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @include breakpoint($medium-up) {
        background-color: transparent;
        border-bottom: 1px solid $color-cream-stroke;
        margin-top: 30px;
        padding: 16px $container-pad-landscape 0;
      }
    }
    &--filter-option-checkbox {
      margin-#{$ldirection}: 20px;
      margin-bottom: 20px;
      width: 100%;
      &.no-results {
        cursor: default;
        .elc-checkbox-icon {
          cursor: default;
        }
      }
      @include breakpoint($medium-up) {
        width: 25%;
        height: 32px;
        margin: 0 0 16px;
        @if $text-direction == rtl {
          margin-left: 0 !important;
        }
      }
      .elc-checkbox {
        display: flex;
        &-label {
          @include body-text;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          height: 20px;
        }
        &-check-area {
          background-color: $white;
          border: 1px solid $color-cream-stroke;
          display: inline-flex;
          height: 20px;
          margin-#{$rdirection}: 12px;
          overflow: hidden;
          top: unset;
          width: 20px;
          @include breakpoint($medium-up) {
            height: 20px;
          }
        }
        &-icon {
          background-color: unset;
        }
        &-selected {
          border: 1px solid $color-primary-700;
          .elc-checkbox-icon {
            @include svg-icon-inline-mask('tick_box_checked.svg');
            background: $color-primary-700;
            transform: scale(1.7);
            height: 20px;
            width: 20px;
            @include breakpoint($medium-up) {
              height: 18px;
            }
          }
        }
      }
    }
    &--filters-modal-button {
      @include body-text;
      background: transparent;
      border: 0;
      color: $color-black;
      display: inline-block;
      font-weight: normal;
      padding: 10px 20px;
      text-transform: capitalize;
      width: auto;
      &:after {
        @include svg-icon-inline-mask('chevron.svg');
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        background: $color-black;
        margin-#{$ldirection}: 9px;
        transform: rotate(90deg);
        vertical-align: middle;
      }
      &:hover {
        background: none;
      }
      .elc-filter-icon {
        display: none;
      }
    }
  }
}

.elc-responsive-modal-mask .elc-mobile-filters-modal {
  .elc-overlay-wrapper-close-button {
    #{$rdirection}: -4px;
    background-color: transparent;
    position: absolute;
    top: 2px;
    .elc-remove-icon {
      height: 20px;
      transform: scale(1.5);
      width: 20px;
    }
  }
  .elc-filter-by-label {
    @include title--h1;
    color: inherit;
    padding: 40px 0 38px;
    text-align: center;
    width: 100%;
  }
}
